.selectWidth {
	width: 500px !important;
}

.ModelContainer {
	width: fit-content !important;
	padding-bottom: 30px !important;
}

@media only screen and (max-width: 640px) {
	.ModelContainer {
		width: 80% !important;
	}
}
