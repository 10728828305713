.ModelContainer {
	width: auto !important;
}

.CameraModalContainer {
	width: auto !important;
	height: auto !important;
}

.modalHeight{
	padding-top: 120px !important;
}
