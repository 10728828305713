@keyframes wave {
	0%,
	100% {
		opacity: 0;
	}
	50% {
		opacity: 1;
	}
}

.speakericon {
	position: relative;
}

.speakericon::before,
.speakericon::after {
	content: '';
	position: absolute;
	top: 0;
	left: 100%;
	width: 200px;
	height: 180px;
	border-radius: 50%;
	border: 2px solid #6db7f2;
	animation: wave 2s ease-in-out infinite;
}

.speakericon::after {
	width: 160px;
	height: 160px;
	animation-delay: 1s;
}
