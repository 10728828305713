.ModelContainer {
	width: auto !important;
	height: auto;
	z-index: 1000 !important;
}
.container{
	width: fit-content !important;
	height: full !important;
	z-index: 1000 !important;
	margin: 0 !important;
}