@tailwind base;
@tailwind components;
@tailwind utilities;

body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
		'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	background-color: #f8f8f8;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}

.css-h0q0iv-MuiButtonBase-root-MuiTab-root.Mui-selected {
	color: #6db7f2 !important;
}

.css-ci5apu-MuiSvgIcon-root {
	color: #6db7f2 !important;
}

.css-ci5apu-MuiSvgIcon-root:hover {
	color: #fff !important;
}

.MuiTabs-indicator {
	background-color: #6db7f2 !important;
}

.css-1h9z7r5-MuiButtonBase-root-MuiTab-root {
	padding: 0px !important;
	font-weight: 600 !important;
}

.css-h0q0iv-MuiButtonBase-root-MuiTab-root {
	font-weight: 600 !important;
	font-family: 'Roboto' !important;
}

.css-1h9z7r5-MuiButtonBase-root-MuiTab-root {
	font-family: 'Roboto' !important;
}

.text-website {
	width: 500px !important;
}

.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline,
.MuiOutlinedInput-root:focus .MuiOutlinedInput-notchedOutline,
.MuiOutlinedInput-root:active .MuiOutlinedInput-notchedOutline {
	border-color: #6db7f2 !important;
	/* Change the border color to red */
}

textarea::placeholder {
	font-size: 24px !important;
}

.scroll-container {
	height: 550px !important;
	overflow-y: scroll;
}
.scroll-container {
	overflow: auto;
	scrollbar-width: thin;

	scrollbar-color: #6b6b6b #6db7f2;
}

.scroll-container::-webkit-scrollbar {
	width: 8px;
}

.scroll-container::-webkit-scrollbar-track {
	background-color: #f1f1f1;
}

.scroll-container::-webkit-scrollbar-thumb {
	background-color: #6db7f2;
}

.scroll-container::-webkit-scrollbar-thumb:hover {
	background-color: #6b6b6b;
}
.css-19kzrtu {
	padding-left: 0px !important;
	padding-right: 0px !important;
}
/* tabs */
.video_con {
	padding: 0px !important;
}

.tabs-list {
	display: flex !important;
	justify-content: flex-start !important;
	align-items: center !important;
	gap: 10px !important;
}

.label__invoice {
	width: 120px !important;
}

.add-item-btn {
	width: 35px !important;
	height: 35px !important;
}
.total-title {
	font-size: 20px;
	font-weight: bold;
}

.input-number {
	width: 105px !important;
}
.add-items-text {
	color: #6db7f2;
	font-size: 20px !important;
	padding-left: 2px;
}
.skyblue__button {
	background-color: #6db7f2 !important;
	color: white !important;
	border-radius: 10px !important;
	margin-top: 5px !important;
	padding-top: 10px !important;
	padding-bottom: 10px !important;
}
.css-md26zr-MuiInputBase-root-MuiOutlinedInput-root,
.css-8ewcdo-MuiInputBase-root-MuiOutlinedInput-root {
	border-radius: 15px !important;
}
.save-invoice {
	background-color: #6db7f2 !important;
	color: white !important;
	border-radius: 10px !important;
	margin-top: 5px !important;
	padding: 15px !important;
}

.outline__button {
	border: 1.71px solid #6db7f2 !important;
	color: #6db7f2 !important;
	border-radius: 10px !important;
	margin-top: 5px !important;
}
.iframe__video {
	width: 100% !important;
	height: 230px !important;
}

.thead__table {
	background-color: #6b6b6b;
	padding: 20px;
	color: #fff;
}
/* custom select input */
.custom-select {
	background-color: red !important;
}

.user__profile__text {
	color: #6b6b6b !important;
	font-size: 14px;
}

.user__card__wrapper {
	border-radius: 20px;
	border-color: #6db7f2 !important;
	border-width: 1.5px !important;
}
.hightlight_lang {
	padding-bottom: 16px !important;
	color: #6db7f2 !important;
	border-bottom: 2px solid #6db7f2 !important;
}

.text-loader {
	border: 4px solid #f3f3f3;
	border-top: 4px solid #3498db;
	border-radius: 50%;
	width: 40px;
	height: 40px;
	animation: text-loader-spin 2s linear infinite;
}

.box-height {
	height: 400px !important;
}

.select-width {
	width: 500px !important;
}

@keyframes text-loader-spin {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
}

.form-signin {
	width: 450px !important;
	padding: 20px;
}

.style-input {
	width: 100% !important;
	background: white;
	border: 1.21px solid #797979;
	border-radius: 10px !important;
	font-size: 12px !important;
}

.style-input-user {
	background: white;
	border-radius: 5px !important;
	font-size: 16px !important;
	height: 50px !important;
	padding-left: 10px;
}

.tab-1,
.tab-2,
.tab-3,
.tab-4,
.tab-5,
.tab-6 {
	margin-bottom: 40px !important;
	/* z-index: 1000; */
}

.submit-button {
	width: 100% !important;
	background-color: #6db7f2 !important;
	text-transform: capitalize !important;
	font-size: 16px !important;
	height: 50px;
}

.google-link-btn {
	width: 100% !important;
	background-color: #fff !important;
	/* border: 1.21px solid #797979 !important; */
	text-transform: capitalize !important;
	color: #6b6b6b !important;
	font-size: 12px !important;
	height: 50px;
}

.microsoft-link-button {
	width: 100% !important;
	background: #fff !important;
	text-transform: capitalize !important;
	font-size: 12px !important;
	color: #000 !important;
	height: 50px;
	margin-top: 12px !important;
	font-weight: 600 !important;
	display: flex !important;
	justify-content: space-between !important;
	padding: 0px 16px !important;
}

.edit-button {
	font-size: 12px !important;
	text-transform: capitalize !important;
	width: 120px;
	margin-bottom: 10px !important;
}

.get-btn {
	font-size: 16px !important;
	text-transform: capitalize !important;
	margin-bottom: 10px !important;
	background-color: #6db7f2 !important;
	border-radius: 8px !important;
	padding-top: 10px !important;
	padding-bottom: 10px !important;
}

.get-btn-mobile {
	font-size: 16px !important;
	text-transform: capitalize !important;
	margin-bottom: 10px !important;
	background-color: #6db7f2 !important;
	border-radius: 8px !important;
	padding-top: 10px !important;
	padding-bottom: 10px !important;
	width: 60%;
}

.login-btn-header {
	color: #707070 !important;
	font-size: 16px !important;
	padding-right: 20px !important;
	text-transform: capitalize !important;
}

.change-profile-btn {
	background-color: #6db7f2 !important;
}

.delete-profile-btn {
	border: 1px solid #6db7f2 !important;
	color: #6db7f2;
}

.grey-box {
	height: 450px !important;
}

.save-btn {
	background-color: #6db7f2 !important;
	width: 300px !important;
	height: 50px !important;
}

.saveuser-btn {
	background-color: #6db7f2 !important;
	width: 80px !important;
	height: 40px !important;
}

.back-btn {
	border: 1px solid #6db7f2 !important;
	width: 80px !important;
	height: 40px !important;
	color: #6db7f2 !important;
}

@media (min-width: 1200px) {
	.css-1oqqzyl-MuiContainer-root {
		max-width: unset !important;
	}

	.css-1qsxih2 {
		max-width: unset !important;
	}
}

:focus-visible {
	outline: none !important;
}

.right-text {
	height: 6rem;
}

.profiles-user-image {
	display: flex !important;
	justify-content: space-between;
	align-items: center;
}

.menu-list li {
	position: relative;
	list-style: none;
}

.menu-list li a {
	position: relative;
	display: inline-block;
	padding-bottom: 4px;
	/* Adjust as needed */
}

.menu-list li a:hover {
	color: #6db7f2;
}

.menu-list li a:active {
	color: #6db7f2 !important;
}

.menu-list li a::before,
.menu-list li a::after {
	content: '';
	position: absolute;
	bottom: -5px;
	width: 0;
	height: 2px;
	background-color: #6db7f2;
	/* Adjust color as needed */
	transition: width 0.3s ease;
}

.menu-list li a::before {
	left: 50%;
	transform: translateX(-50%);
}

.menu-list li a::after {
	right: 50%;
	transform: translateX(50%);
}

.menu-list li a:hover::before,
.menu-list li a:hover::after {
	width: 100%;
	/* Adjust width as needed */
}

.mobile-menu-icon {
	display: none !important;
}

.destop-menu {
	display: block !important;
}

.translate-btn {
	color: #6db7f2 !important;
	border: 2px solid #6db7f2 !important;
}

.translate-btn:hover {
	background-color: #6db7f2 !important;
	color: #fff !important;
	border: 2px solid #6db7f2 !important;
}

.secure-icon {
	width: 150px !important;
	height: 150px !important;
	display: flex !important;
	justify-content: center !important;
	align-items: center;
}

.secure-img {
	width: 300px !important;
	height: 300px !important;
	background-color: white !important;
	border-radius: 50%;
	display: flex !important;
	justify-content: center !important;
	align-items: center;
}

.translate-st {
	word-wrap: break-word;
}

@media (max-width: 667px) {
	.tab-1,
	.tab-2,
	.tab-3,
	.tab-4,
	.tab-5,
	.tab-6 {
		margin-bottom: 40px !important;
		z-index: 1000;
	}

	.tabstyle-1 {
		padding-left: 6rem !important;
		padding-right: 6rem !important;
	}

	.tabstyle-2 {
		padding-left: 5.5rem !important;
		padding-right: 5.5rem !important;
	}

	.tabstyle-3 {
		padding-left: 4.5rem !important;
		padding-right: 4.5rem !important;
	}

	.tabstyle-4 {
		padding-left: 5rem !important;
		padding-right: 5rem !important;
	}

	.tabstyle-5 {
		padding-left: 6rem !important;
		padding-right: 6rem !important;
	}

	.tabstyle-6 {
		padding-left: 4.5rem !important;
		padding-right: 4.5rem !important;
	}

	.mobile-menu-icon {
		display: block !important;
		z-index: 300 !important;
	}

	.destop-menu {
		display: none !important;
	}

	.css-1160xiw-MuiPaper-root-MuiDrawer-paper {
		width: 50% !important;
	}

	.secure-icon {
		width: 80px !important;
		height: 80px !important;
	}

	.secure-img {
		width: 150px !important;
		height: 150px !important;
	}

	.subcribe-secure {
		font-size: 36px !important;
		text-align: center;
	}

	.plans-secure {
		font-size: 24px !important;
		text-align: center;
		padding-left: 10px;
		padding-right: 10px;
	}

	.overlay-text {
		gap: 0px !important;
	}

	.user__card {
		display: block !important;
		padding-left: 20px;
		padding-right: 20px;
	}

	.user__card__wrapper {
		margin-bottom: 20px;
	}
}

@media (max-width: 556px) {
	.tab_button {
		display: flex;
		justify-content: center;
		flex-direction: column;
		flex-wrap: wrap;
		width: 100% !important;
	}

	.tab_wrapper {
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
	}
}

@media (min-width: 769px) and (max-width: 900px) {
	.mobile-selector {
		display: block !important;
	}

	.right-slector {
		display: none !important;
	}

	.left-slector-mobile {
		display: none !important;
	}

	.destop-menu {
		display: none !important;
	}

	.css-1160xiw-MuiPaper-root-MuiDrawer-paper {
		width: 50% !important;
	}

	.secure-icon {
		width: 100px !important;
		height: 100px !important;
	}

	.secure-img {
		width: 180px !important;
		height: 180px !important;
	}

	.subcribe-secure {
		font-size: 36px !important;
		text-align: center;
	}

	.plans-secure {
		font-size: 24px !important;
		text-align: center;
		padding-left: 10px;
		padding-right: 10px;
	}

	.overlay-text {
		gap: 0px !important;
	}
}

@media (min-width: 1024px) {
	.mobile-menu-icon-userPortal {
		display: none !important;
		z-index: 300 !important;
	}
}

@media (max-width: 1024px) {
	.mobile-menu-icon-userPortal {
		display: flex !important;
		z-index: 300 !important;
	}
}

@media (max-width: 767px) {
	.menulist {
		text-align: center !important;
	}

	.get-btn-mobile {
		margin-left: 20% !important;
		margin-right: 20% !important;
	}

	.css-zylse7-MuiButtonBase-root-MuiIconButton-root {
		position: absolute !important;
		top: 20px;
		right: 10px;
	}

	.grid {
		gap: 10px;
	}

	.right-text {
		height: 1rem;
	}

	.profile-user-text {
		margin-top: 350px !important;
		margin-bottom: 20px !important;
	}

	.form-signin {
		width: 400px !important;
		padding: 20px;
		padding-bottom: 120px !important;
	}

	.logo-img {
		padding-top: 30px;
		margin-bottom: 0px !important;
	}

	.left-wrapper {
		padding-top: 90px !important;
	}

	/* .main-header{
    display: none !important;
  } */
	.translate-tabs {
		/* width: unset !important; */
		display: flex;
		flex-direction: column;
		padding-top: 4rem;
	}

	.tab-1,
	.tab-2,
	.tab-3,
	.tab-4,
	.tab-5,
	.tab-6 {
		margin-bottom: 40px !important;
	}

	.tabstyle-1 {
		padding-left: 6rem !important;
		padding-right: 6rem !important;
	}

	.tabstyle-2 {
		padding-left: 5.5rem !important;
		padding-right: 5.5rem !important;
	}

	.tabstyle-3 {
		padding-left: 4.5rem !important;
		padding-right: 4.5rem !important;
	}

	.tabstyle-4 {
		padding-left: 5rem !important;
		padding-right: 5rem !important;
	}

	.tabstyle-5 {
		padding-left: 3.8rem !important;
		padding-right: 3.8rem !important;
	}

	.tabstyle-6 {
		padding-left: 4.5rem !important;
		padding-right: 4.5rem !important;
	}

	.text-trans .right-slector {
		display: none !important;
	}

	.left-slector-mobile {
		display: none !important;
	}

	.text-website {
		width: 250px !important;
		margin-bottom: 20px !important;
	}

	.arrow-btn {
		margin-bottom: 20px !important;
	}

	.curve-img-mobile {
		z-index: -111 !important;
	}

	.translation-tabs {
		z-index: 999 !important;
	}

	.box-height {
		height: 450px !important;
	}

	.close-btn {
		position: absolute;
		top: -10px;
		left: -20px;
		width: 30px;
		height: 30px;
	}

	.close-icon {
		/* margin-bottom: px !important; */
		/* margin-left: -2px !important; */
	}

	.mobile-menu-icon {
		display: block !important;
		z-index: 300 !important;
	}

	.destop-menu {
		display: none !important;
	}

	.css-1160xiw-MuiPaper-root-MuiDrawer-paper {
		width: 80% !important;
		padding-top: 100px;
	}

	.close-btn-img {
		position: absolute;
		top: 2px;
		left: -20px;
		width: 30px;
		height: 30px;
	}

	.close-icon-img {
		margin-bottom: 10px !important;
		margin-left: -6px !important;
	}

	.output-type-btn {
		padding-left: 45px !important;
	}

	.secure-icon {
		width: 80px !important;
		height: 80px !important;
	}

	.secure-img {
		width: 150px !important;
		height: 150px !important;
	}

	.subcribe-secure {
		font-size: 36px !important;
		text-align: center;
		margin-top: -100px !important;
	}

	.plans-secure {
		font-size: 24px !important;
		text-align: center;
		padding-left: 10px;
		padding-right: 10px;
	}

	.overlay-text {
		gap: 0px !important;
	}

	.user__card {
		display: block !important;
		padding-left: 20px;
		padding-right: 20px;
	}

	.user__card__wrapper {
		margin-bottom: 20px;
	}
}

@media (min-width: 768px) and (max-width: 1024px) {
	.menulist {
		text-align: center !important;
	}

	.get-btn-mobile {
		margin-left: 20% !important;
		margin-right: 20% !important;
	}

	.css-1160xiw-MuiPaper-root-MuiDrawer-paper {
		width: 70% !important;
		padding-top: 200px !important;
	}

	.css-zylse7-MuiButtonBase-root-MuiIconButton-root {
		position: absolute !important;
		top: 20px;
		right: 10px;
	}
}

@media (min-width: 1025px) and (max-width: 1300px) {
	.secure-icon {
		width: 150px !important;
		height: 150px !important;
	}

	.secure-img {
		width: 250px !important;
		height: 250px !important;
	}

	.subcribe-secure {
		font-size: 36px !important;
		text-align: left;
		padding-left: 10px;
	}

	.plans-secure {
		font-size: 24px !important;
		text-align: left;
		padding-left: 10px;
		padding-right: 10px;
	}

	.overlay-text {
		gap: 20px !important;
	}
}

@media (min-width: 1025px) {
	.grid {
		gap: 20px;
	}

	.text-trans .mobile-selector {
		display: none !important;
	}
}

.profile-menu-list .css-1ka5eyc-MuiPaper-root-MuiMenu-paper-MuiPopover-paper {
	position: absolute !important;
	top: 10px !important;
	right: 20px !important;
	width: 150px !important;
}

.profile-menu-list .css-oapmtd {
	position: absolute !important;
	top: 80px !important;
	left: unset !important;
	right: 20px !important;
	width: 150px !important;
}

@media (min-width: 901px) and (max-width: 1024px) {
	.mobile-selector {
		display: none !important;
	}

	.css-zylse7-MuiButtonBase-root-MuiIconButton-root {
		position: absolute !important;
		top: 20px;
		right: 10px;
	}

	.secure-icon {
		width: 80px !important;
		height: 80px !important;
	}

	.secure-img {
		width: 150px !important;
		height: 150px !important;
	}

	.subcribe-secure {
		font-size: 36px !important;
		text-align: left;
		padding-left: 10px;
	}

	.plans-secure {
		font-size: 24px !important;
		text-align: left;
		padding-left: 10px;
		padding-right: 10px;
	}

	.overlay-text {
		gap: 20px !important;
	}
}
