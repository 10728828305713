.active {
	background-color: #6db7f2;
	color: white;
}
@media (min-width: 1300px) {
	.lgm\:px-6 {
		padding-right: 1rem;
		padding-left: 1rem;
	}
}
