.loader {
    border: 3px solid #f3f3f3; /* Adjust size as needed */
    border-radius: 50%;
    /* Adjust size as needed */
    width: 20px; /* Adjust size as needed */
    height: 20px; /* Adjust size as needed */
    animation: spin 1s linear infinite;
    display: inline-block; /* To align with text */
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  