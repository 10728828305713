#scrollbar {
	overflow-y: scroll;
	padding-right: 10px;
}
#scrollbar::-webkit-scrollbar {
	margin-left: 15px;
	width: 7px;

	// background-color: ;
}
/* Track */
#scrollbar::-webkit-scrollbar-track {
	background: #beddf8;
}

/* Handle */
#scrollbar::-webkit-scrollbar-thumb {
	background: #6db7f2;
}

/* Handle on hover */
#scrollbar::-webkit-scrollbar-thumb:hover {
	background: #0982ec;
}
