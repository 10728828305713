.speaker-container {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 200px;
	height: 200px;
  }
  
  #speaker {
	width: 100%;
	height: 100%;
  }
  
  .sound-wave {
	position: absolute;
	width: 0;
	height: 0;
	border-radius: 50%;
	background-color: #000;
	opacity: 0;
  }
  
  .sound-wave::before,
  .sound-wave::after {
	content: "";
	position: absolute;
	width: 100%;
	height: 100%;
	border-radius: 50%;
	background-color: #000;
	opacity: 0.5;
  }
  
  .sound-wave::after {
	transform: scale(1.5);
	opacity: 0.3;
  }
  